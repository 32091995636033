@use "sass:color";@use 'roedl' as *;.MiniTile {
  .mini-tile-header {
    .la-copy {
      color: $brand-color;
    }

    .Icon::before {
      vertical-align: middle;
    }
  }
}
