@use "sass:color";@use 'roedl' as *;.Breadcrumbs {
  flex: 1;

  ul {
    list-style: none;
    padding-inline-start: 30px;
  }

  .MenuItem {
    padding-right: 15px;
    span {
      overflow: hidden;
      white-space: nowrap;
    }

    &.menu-item-variant-sub-menu {
      padding-left: 0;

      i {
        font-size: 10px;
        cursor: pointer;
      }
    }

    .m-menu__link {
      text-decoration: none;
    }

    .m-menu__link-text {
      color: var(--breadcrumbs-text);
      font-weight: 400;
      font-size: 1.07rem;
      &:hover {
        color: var(--breadcrumbs-text-hover);
      }
    }

    .PageTitle {
      display: block;
      font-size: 1.07rem;
      font-weight: 500;
    }
  }
}
