@use "sass:color";@use 'roedl' as *;.UserMenu {
  margin-right: 20px;

  .user-menu-target > .user-menu-avatar {
    cursor: pointer;
  }

  .fallback-profile-pic {
    width: 100%;
    height: 100%;
  }
}

.user-menu-dropdown {
  .user-menu-header {
    display: flex;
    align-items: center;
    background-size: cover;
    background-image: url("../../../default/assets/img/user_profile_bg.jpg");
    padding: 20px 20px;
    min-width: 325px;
    height: 100px;
  }

  .user-menu-icon {
    margin-right: 0.75rem;
  }

  .logout-button {
    width: 110px;
    border-radius: 60px;
    font-size: 1.2rem;
    font-weight: 500;
  }
}
