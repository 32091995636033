@use "sass:color";@use 'roedl' as *;.TodosV2 {
  .tabs {
    .tabs-list {
      button:focus {
        outline: none;
      }

      .OptiSupportHelpLink {
        position: absolute;
        right: 0px;
      }
    }
  }
}
