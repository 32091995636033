@use "sass:color";@use 'roedl' as *;.FixedPriceTable {
  .mantine-Accordion-item {
    box-shadow: none;
  }

  .mantine-Accordion-chevron {
    margin-inline-start: 0;
  }

  .mantine-Accordion-label {
    font-size: 1rem;
  }

  .mantine-Accordion-content {
    padding: 0;
  }
}
