@use "sass:color";@use 'roedl' as *;.PopupBox {
  position: absolute;
  z-index: 1;
  max-width: 280px;

  .mantine-NavLink-root {
    padding: 4px 0;
    line-height: 1;

    &:where([data-disabled]) {
      pointer-events: initial;
    }

    &:hover {
      background: none;
      color: $primary-hover-color;
    }
  }

  .mantine-NavLink-label {
    font-size: var(--mantine-font-size-xs);
    font-weight: 400;
  }
}
