@use "sass:color";@use 'roedl' as *;.Parkabrechnung {
  .tabs,
  .TabBar {
    padding: 5px;
    background-color: white;
    font-size: 15px;
    box-shadow: var(--mantine-shadow-md);
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}
