@use "sass:color";@use 'roedl' as *;.MultipleEmails {
  .mantine-TagsInput-input {
    border-color: #ebedf2;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    display: flex;
  }

  .mantine-TagsInput-tag {
    background-color: #f8f9fa;
    border-color: #ebedf2;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }
}
