@use "sass:color";@use 'roedl' as *;.ChartLegend {
  overflow-x: hidden;
  overflow-y: auto;

  .legend-item {
    display: flex;
    align-items: center;

    .legend-label {
      text-align: start;
      text-wrap: auto;
    }

    &.inactive {
      .legend-label {
        color: $custom-light-grey;
      }
    }
  }
}
