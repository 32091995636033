@use "sass:color";@use 'roedl' as *;.TableLight {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  table {
    width: -webkit-fill-available;

    td {
      padding: 0.75rem;
    }

    .NoDataComponent {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .Pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .pagination-buttons {
      margin-left: 2rem;
      margin-right: 2rem;

      button {
        border-radius: 5px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .IconButton {
        padding-left: 5px;
        padding-right: 5px;
        color: $custom-grey;
      }

      .IconButton:disabled {
        color: $custom-light-grey !important;
      }
    }
  }
}
