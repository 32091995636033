@use "sass:color";@use 'roedl' as *;.EnergyDataUploadPage {
  .ReactTable {
    .rt-th {
      text-align: left;
    }

    .rt-td {
      margin: auto;

      .FormField {
        width: 95%;
        margin: 0 auto;
        align-self: center;
      }
    }

    .rt-tr.highlighted {
      background-color: #cef5f2;
    }
  }

  .AnImportIsStoppedWarning {
    .alert {
      margin-bottom: 1.5em;
    }
  }

  .send-help-button {
    width: 200px;
    margin: 0 5px;
  }

  .no-data-component {
    text-align: center;

    .loader {
      margin: 20px 0;
    }

    p {
      margin-top: 10px;
    }
  }
}
