@use "sass:color";@use 'roedl' as *;.Paragraph6Nav {
  padding: 5px;
  background-color: white;
  font-size: 15px;
  box-shadow: var(--mantine-shadow-md);

  .LoadOrError {
    display: flex;
    align-items: center;
    margin-left: 1em;
  }
}
