@use "sass:color";@use 'roedl' as *;.CustomTextElementPopup {
  .mantine-Select-root {
    display: flex;
    align-items: center;
    gap: var(--mantine-spacing-xs);
  }

  .mantine-Select-label {
    width: 40px;
    margin: 0;
  }

  .mantine-Select-wrapper {
    width: 110px;
  }
}
