@use "sass:color";@use 'roedl' as *;.SiteSetupAssistantNavigation {
  .site-setup-assistant-navigation-alert {
    margin-bottom: 30px;
  }
  .FormField2 {
    flex-grow: 1;
  }
  .error {
    &:not(.active) {
      .mantine-Stepper-stepIcon {
        background-color: $danger-color;
        color: white;
      }
    }
  }
  .AddressFormItems {
    button {
      width: 300px;
      padding: 16px;
    }

    .supply-type-button.active {
      background-color: #fff;
      border: 2px solid $brand-color;
    }

    .Icon {
      font-size: 36px;
      margin: 26px;
    }

    img {
      font-size: 36px;
      height: 36px;
      width: 36px !important;
      margin: 8px;
    }
  }

  .MeterFormItems {
    .IconAlert {
      margin-bottom: var(--mantine-spacing-md);
    }
  }
}
