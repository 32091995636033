@use "sass:color";@use 'roedl' as *;.ParkabrechnungContractTitle {
  display: flex;
  align-items: center;
  user-select: none;

  .action-panel {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .edit-icon {
    display: flex;
  }
}
