@use "sass:color";@use 'roedl' as *;.ColorSelectionModal {
  .mantine-ActionIcon-root {
    border: 2px solid transparent;
    padding: 2px;

    &:hover {
      border-color: $custom-light-grey;
    }

    &.color-selected {
      border-color: $node-energy-black;
    }
  }

  .color-pane {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
