@use "sass:color";@use 'roedl' as *;// all the hardcoded colors in this files are the same in all themes at the moment - maybe change to a variable in the future
.MenuItemStyle {
  color: #868aa8;
  font-weight: 400;
  padding: 0;
  padding-block: 1rem;

  a {
    color: inherit;
    text-decoration: none;

    .caret-container {
      display: grid;

      .caret {
        translate: 0.8em;
        color: var(--body-background);
        display: none;
        align-items: center;
        justify-content: end;
      }

      > * {
        grid-column: 1;
        grid-row: 1;
      }
    }

    &[aria-current="page"] {
      color: #fff;
      font-weight: 500;
      background: #282a3a;

      .caret {
        display: grid;
      }

      i {
        color: var(--mantine-color-brand-filled);
      }
    }
  }

  i {
    color: #525672;
    line-height: 1;
  }

  &:hover {
    background: #292b3a;

    a {
      color: inherit;
    }

    i {
      color: #8c8ea4;
    }
  }
}
